@media (min-width: 1650px) {
  .main-container.boxed {
    max-width: 1920px;
    margin: 0 auto;
  }
}

@media (max-width: 1600px) {
  .main-container.boxed {
    max-width: 1300px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .navbar-header {
    width: 225px;
    flex-shrink: 0;
    .navbar-brand {
      padding-top: 0px;
    }
  }
}

@media (max-width: 767px) {
  .mat-toolbar-single-row {
    height: 64px !important;
  }

  .d-flex {
    display: block;
  }

  .no-block {
    display: flex;
  }
}
