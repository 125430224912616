.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

html .page-wrapper {
  height: calc(100vh - 64px);
  .page-content {
    padding: 15px;
  }
}

.align-items-center {
  align-items: center;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

body {
  margin: 0px;
}
