@use '@angular/material' as mat;

$primary: mat.define-palette(mat.$blue-palette, 900);
$accent: mat.define-palette(mat.$indigo-palette, 500);
$warn: mat.define-palette(mat.$pink-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);
$font-family: 'Roboto';
@include mat.core();

@include mat.all-component-themes($theme);

$sidebar-text: #607d8b;
$sidebar-icons: #002849;

$font-16: 16px;

$themecolor: #1e88e5;
$themecolor-alt: #e6eef8;
$sidebar-textcolor: #002849;
$themecolor-dark: #028ee1;

$white: #ffffff;

$radius: 4px;
