@import 'variables';
@import 'app';
@import 'header';
@import 'pages';
@import 'sidebar';
@import 'responsive';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@font-face {
  font-family: SF-Pro-Text;
  src: url(../fonts/SF-Pro-Text-Regular-webfont.woff) format('woff');
}
