mat-toolbar {
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);

  &.topbar {
    background: $sidebar-textcolor;
  }

  .navbar-header {
    text-align: center;
    display: flex;
    .navbar-brand {
      display: flex;
      line-height: 18px;
      align-items: center;
      b {
        margin-right: 5px;
      }

      .cci-branding-logo-expanded {
        width: 200px;
        height: 57px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
}

.topbar {
  position: relative;
  z-index: 2;
}

.cci-sidenav-container {
  flex: 1 0 auto;
}

@media only screen and (max-width: 400px) {
  mat-toolbar {
    box-shadow: none;
    &.topbar {
      border: 0px;
      background: $white;
    }
  }
  .cci-branding-logo-expanded {
    margin: auto;
    height: 56px;
    width: 200px;
  }
}
