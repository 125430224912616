@media only screen and (min-width: 400px) {
  .sidenav_container {
    height: calc(100vh - 100px);
    width: 64px;
    background-color: white;
  }

  #snav {
    .mat-list-item {
      margin-bottom: 4px;
      margin-top: 4px;

      a {
        height: 56px;
        font-size: 10px;
        line-height: 14px;
        flex-direction: column;
        justify-content: center;
      }

      span {
        margin-top: 4px;
      }

      &.selected > .mat-list-item-content > a {
        border-radius: 16px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .sidenav_container {
    width: calc(100vw - 50px);
  }
  #snav {
    .mat-list-item {
      a {
        height: 40px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;

        color: $sidebar-textcolor;

        i {
          padding-right: 16px;
          padding-left: 16px;
        }
      }

      &.selected > .mat-list-item-content > a {
        border-radius: 0 16px 16px 0;
      }
    }
  }
}

mat-sidenav {
  padding-top: 0px;
}

#snav {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  .mat-list-item {
    height: auto;

    &:hover {
      background: none;
    }

    .mat-list-item-content {
      display: block;
      padding: 0 4px;

      &:hover {
        background: none;
        > a {
          color: $themecolor;
        }
      }
    }

    a {
      align-items: center;
      display: flex;
      font-family: $font-family;

      mat-icon:not(.dd-icon) {
        display: block;
        height: 24px;
        width: 24px;
      }
    }

    &.selected > .mat-list-item-content > a {
      background: $themecolor-alt;
      color: $sidebar-textcolor;
    }

    &.selected .sub-item .mat-list-item-content a.selected {
      background: transparent;
      font-weight: 500;
    }
  }
}

.sidebar-closed #snav {
  &.mat-sidenav ~ .mat-drawer-content {
    margin-left: 0px !important;
  }
}

.spacer {
  flex: 1 1 auto;
}
