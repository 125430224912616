body {
  font-family: Roboto;
}

.pointer {
  cursor: pointer;
}

.searchicon {
  margin: 4px 4px 0 0;
}

.show-filter-button {
  float: left;
  margin-left: 10px;
}

.mat-table {
  margin-top: 15px;
}

.formbutton {
  margin-right: 5px;
}

.downloadbutton {
  float: right;
}

.hidden {
  display: none;
}

.inline-block {
  display: inline-block;
}

.tablecheckbox {
  height: 20px;
  width: 20px;
}

.page-title {
  height: 28px;
  width: 62px;
  color: #002849;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
}

.text {
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.mat-snack-bar-container .flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.mat-snack-bar-container.success {
  color: rgba(255, 255, 255, 255);
}

.mat-snack-bar-container.error {
  color: rgba(255, 0, 0, 1);
}

.mat-snack-bar-container .flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.material-icons {
  display: inline-flex;
  vertical-align: top;
}

mat-header-cell,
mat-header-row,
.mat-header-cell-def {
  font-weight: bold;
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  height: 20px;
}

.page-title-search-bar-show-filters {
  display: flex;
  //height: 48px;
  align-items: center;
  padding-bottom: 5px;
}

.page-title-search-bar-show-filters > .title-div {
  float: left;
  width: 10%;
}

.page-title-search-bar-show-filters > .filter-options {
  display: flex;
  align-items: center;
  float: left;
}

.page-title-search-bar-show-filters > .create-button {
  margin-left: auto;
  float: right;
}

.actionbutton {
  height: 48px;
  width: 125px;
}

.row-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.no-records {
  padding: 10px;
  text-align: center;
}

.mat-cell,
.mat-header-cell {
  padding-left: 4px;
  height: auto;
  width: auto;
  word-break: break-word;
}

.mat-header-cell {
  color: black;
}

.mat-cell {
  font-size: small;
}

mat-row,
mat-header-row,
mat-footer-row {
  height: auto;
  width: auto;
}

.filter-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 960px) {
  .filter-list {
    justify-content: center;
    flex-direction: column;
    div {
      text-align: center;
    }
  }
  .mat-table {
    border: 0;
    vertical-align: middle;
  }
  .mat-table .mat-header-cell {
    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .mat-table .mat-row {
    border-bottom: 5px solid #ddd;
    display: block;
  }
  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
    font-weight: bold;
    height: 30px;
    margin-bottom: 4%;
  }
  .mat-table .mat-cell:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.85em;
  }
  .mat-table .mat-cell:last-child {
    border-bottom: 0;
  }
  .mat-table .mat-cell:first-child {
    margin-top: 4%;
  }
  .cci-toolbar-button {
    color: black;
  }
}

@media (max-width: 576px) {
  .page-title-search-bar-show-filters {
    flex-flow: row wrap;
  }
  .page-title-search-bar-show-filters > .title-div {
    order: 1;
  }
  .page-title-search-bar-show-filters > .create-button {
    order: 2;
  }
  .page-title-search-bar-show-filters > .filter-options {
    order: 3;
    margin-top: 20px;
    width: 100%;
  }
}

.actionbutton {
  height: 48px;
  width: 125px;
}

.no-records {
  padding: 10px;
  text-align: center;
}

.errorText {
  color: red;
  text-align: center;
}

.lable {
  font-size: small;
  font-weight: 500;
}

a {
  color: #085abd;
}
//set white background for unselected matchip
.mat-chip.mat-standard-chip:after {
  background: white !important;
}
